<template>
  <!--  begin:: generated menu item (parent)  -->
  <li
    v-if="nav.type === 'menu-nav-parent' && comparePermission(nav.guard)"
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item menu-item-submenu"
    v-bind:class="[hasActiveChildren(nav.url.path) ? 'menu-item-open' : '']"
  >
    <a href="#" class="menu-link menu-toggle">
      <span class="svg-icon menu-icon">
        <inline-svg :src="nav.icon" />
      </span>
      <span class="menu-text"> {{ nav.text }} </span>
      <i class="menu-arrow"></i>
    </a>

    <div
      class="menu-submenu menu-submenu-classic menu-submenu-right"
      v-for="child in nav.children"
      :key="child.text"
    >
      <ul class="menu-subnav">
        <AppParentMenu
          v-if="'children' in child && child.children.length"
          :nav="child"
        ></AppParentMenu>
        <router-link
          v-else
          :to="child.url.path ? child.url.path : { name: child.url.name }"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-bullet menu-bullet-dot"><span></span></i>
              <span class="menu-text"> {{ child.text }} </span>
            </a>
          </li>
        </router-link>
      </ul>
    </div>
  </li>
  <!--  begin:: generated menu item (parent)  -->
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppParentMenu",
  props: ["nav"],
  methods: {
    /**
     * @param guard assigned to route
     * @returns {boolean}
     */
    comparePermission(guard) {
      console.log(
        "Comparing permission",
        guard.permissions,
        this.permissions,
        this.busy
      );
      let status = true;

      if (guard.permissions.length > 0) {
        const similar = this.permissions.filter((item) => {
          return guard.permissions.includes(item.name);
        });
        console.log("Similar Length", similar);
        status = similar.length > 0;
      }

      console.log("Comparison", status);
      return status;
    },

    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
  computed: {
    ...mapState({
      permissions: (state) => state.CoreModule.AsideMenuStore.permissions,
      busy: (state) => state.CoreModule.AsideMenuStore.isBusy,
    }),
  },
};
</script>

<style scoped></style>
