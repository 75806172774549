<template>
  <b-sidebar
    bg-variant="white"
    backdrop
    backdrop-variant="light"
    id="user_profile_sidebar"
    right
    :title="$t('PROFILE.USERPROFILE')"
    sidebar-class="offcanvas"
    header-class="offcanvas-header d-flex mt-5 ml-5 mr-5"
    z-index="auto"
  >
    <template v-slot:header-close>
      <button class="btn btn-sm btn-icon btn-warning btn-hover-danger">
        <i class="ki ki-close icon-xs"></i>
      </button>
    </template>

    <div class="d-flex p-10 align-items-center mt-5">
      <div class="symbol symbol-100 mr-5">
        <img class="symbol-label" :src="picture" alt="" />
        <i class="symbol-badge bg-success"></i>
      </div>
      <div class="d-flex flex-column">
        <a
          href="#"
          class="font-weight-bold font-size-h4 text-dark-75 text-hover-primary"
        >
          {{ profile.name }}
        </a>
        <div class="navi mt-2">
          <a href="#" class="navi-item">
            <span class="navi-link p-0 pb-2">
              <span class="navi-icon mr-1">
                <span class="svg-icon svg-icon-lg svg-icon-primary">
                  <!--begin::Svg Icon-->
                  <inline-svg
                    src="media/svg/icons/Communication/Mail-notification.svg"
                  />
                  <!--end::Svg Icon-->
                </span>
              </span>
              <span>
                {{ profile.email }}
              </span>
            </span>
          </a>
        </div>
        <span class="navi-link p-0 pb-2">
          <span class="navi-icon mr-1">
            <span class="svg-icon svg-icon-lg svg-icon-primary">
              <!--begin::Svg Icon-->
              <inline-svg src="media/svg/icons/Home/Building.svg" />
              <!--end::Svg Icon-->
            </span>
          </span>
          <span>
            {{ profile.organisation }}
          </span>
        </span>
        <button class="btn btn-light-primary btn-bold mt-3" @click="onLogout">
          {{ $t("PROFILE.SIGNOUT") }}
        </button>
      </div>
    </div>
    <!--end::Header-->
    <div class="separator separator-dashed mt-8 mb-5"></div>
  </b-sidebar>
</template>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import { mapGetters, mapState } from "vuex";

export default {
  name: "UserProfileSideBar",
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
  },
  mounted() {
    this.$store.dispatch("fetchAuthProfile");
  },
  computed: {
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    },
    ...mapGetters({
      test: "getUserAuthProfile",
    }),
    ...mapState({
      profile: (state) => state.AuthModule.AuthProfile.profile,
    }),
  },
};
</script>

<style scoped></style>
