var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.busy)?_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
      ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Home/Commode2.svg"}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t("SIDEBAR.DASHBOARD")))])])])]}}],null,false,2562051792)}),_vm._l((_vm.navs),function(nav){return _c('span',{key:nav.name,staticClass:"menu-nav"},[(nav.type === 'menu-section' && _vm.comparePermission(nav.guard))?_c('li',{staticClass:"menu-section"},[_c('h6',{staticClass:"menu-text text-white"},[_vm._v(_vm._s(nav.text))]),_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/General/Other2.svg"}})],1)]):_vm._e(),(nav.type === 'menu-nav' && _vm.comparePermission(nav.guard))?_c('router-link',{attrs:{"to":nav.url.path ? nav.url.path : { name: nav.url.name }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item menu-item-submenu",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon menu-icon"},[_c('inline-svg',{attrs:{"src":nav.icon}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(nav.text)+" ")])])])]}}],null,true)}):_vm._e(),_c('AppParentMenu',{attrs:{"nav":nav}})],1)})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }