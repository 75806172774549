<template>
  <div class="topbar-item">
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-clean btn-sm btn-dropdown">
          <span
            class="
              text-muted
              font-weight-bold font-size-base
              d-none d-md-inline
              mr-1
            "
          >
            {{ $t("PROFILE.HI") }}
          </span>
          <span
            class="
              text-dark-50
              font-weight-bolder font-size-base
              d-none d-md-inline
              mr-3
            "
          >
            {{ profile.name }}
          </span>
          <span class="symbol symbol-35 symbol-light-secondary">
            <img v-if="false" alt="Pic" :src="picture" />
            <span
              v-if="true"
              class="symbol-label font-size-h5 font-weight-bold"
            >
              {{ profile.name ? profile.name.charAt(0) : "" }}
            </span>
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-250px">
        <div class="d-flex flex-column justify-content-lg-center">
          <!--  begin: Corp Section -->
          <p class="m-5 font-weight-bolder text-primary text-uppercase">
            SmartId Education
          </p>
          <!--  end: Corp Section -->

          <!-- begin: Profile Header Section -->
          <p class="mx-5 mt-2 font-weight-bolder text-uppercase">
            {{ profile.name }}
          </p>
          <!-- end: Profile Header Section -->

          <!-- begin: Profile Settings Section -->
          <b-list-group>
            <b-list-group-item class="border-0" to="/personal/profile">
              {{ $t("PROFILE.PROFILE") }}</b-list-group-item
            >
            <b-list-group-item class="border-0" to="/personal/account"
              >{{ $t("PROFILE.ACCSETTINGS") }}
            </b-list-group-item>
          </b-list-group>
          <!-- end: Profile Settings Section -->

          <div class="d-flex border-top flex-column mt-2">
            <button
              class="btn btn-hover-danger text-left btn-bold my-2 mx-1"
              @click="onLogout"
            >
              {{ $t("PROFILE.SIGNOUT") }}
            </button>
          </div>
        </div>
      </b-dropdown-text>
    </b-dropdown>

    <UserProfileSideBar></UserProfileSideBar>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import UserProfileSideBar from "@/modules/core/components/sidebar/UserProfileSideBar";
import { mapState } from "vuex";

export default {
  name: "KTQuickUser",
  components: { UserProfileSideBar },
  data() {
    return {
      list: [
        {
          title: "Another purpose persuade",
          desc: "Due in 2 Days",
          alt: "+28%",
          svg: "media/svg/icons/Home/Library.svg",
          type: "warning",
        },
        {
          title: "Would be to people",
          desc: "Due in 2 Days",
          alt: "+50%",
          svg: "media/svg/icons/Communication/Write.svg",
          type: "success",
        },
        {
          title: "Purpose would be to persuade",
          desc: "Due in 2 Days",
          alt: "-27%",
          svg: "media/svg/icons/Communication/Group-chat.svg",
          type: "danger",
        },
        {
          title: "The best product",
          desc: "Due in 2 Days",
          alt: "+8%",
          svg: "media/svg/icons/General/Attachment2.svg",
          type: "info",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
  },
  computed: {
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    },
    ...mapState({
      profile: (state) => state.AuthModule.AuthProfile.profile,
    }),
  },
};
</script>
