<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-dark">
        <span class="text-muted font-weight-bold mr-2">
          {{ getYear() }}
        </span>
        <a
          href="https://www.pointgate.net"
          class="text-dark-75 text-hover-primary"
          target="_blank"
        >
          Pointgate Systems Sdn Bhd
        </a>
      </div>

      <div class="d-flex justify-content-end">v 2.5</div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters, mapState } from "vuex";
import dayjs from "dayjs";

export default {
  name: "KTFooter",
  methods: {
    getYear() {
      const t = new Date();
      // https://day.js.org/docs/en/display/format
      const year = dayjs(t).format("YYYY").toString();
      return `${year} @`;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapState({
      profile: (state) => state.AuthModule.AuthProfile.profile,
    }),
    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
  },
};
</script>
